h1 {
    font-size: 1.8em;
}

h2 {
    font-size: 1.6em;
}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.2em;
    margin: .5em 0;
}

h6 {
    font-size: .8em;
}

.App {
    min-height: 100vh;
}

.App .logo {
    height: 32px;
    background-image: url(../images/logo.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 16px;
}

.App .menu-footer {
    position: absolute;
    bottom: 1em;
    padding: 0 1em;
    width: 100%;
    color: rgba(255, 255, 255, 0.65);
}

.ant-layout-sider-collapsed > .ant-layout-sider-children {
    display: none;    
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
    position: fixed;
    top: 25px;
    left: 10px;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left > span {
    height: 100%;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left > span > svg {
    height: 1.25em;
    width: 1.25em;
}

/* styles.css */
.text-right-md {
    text-align: right;
  }
  
@media (max-width: 768px) {
    .text-right-md {
        text-align: left; /* or your desired default alignment */
    }
}

@media (min-width: 992px) {
    .App .menu-footer {
        position: fixed;
    }

    .menu-footer .menu-footer-right {
        position: absolute;
        bottom: .1em;
        background-color: #001529;
    }
}

.menu-footer a {
    color: rgba(255, 255, 255, 0.65);
    padding-left: .5em;
    font-size: 1.25em;
}

.App .ant-anchor-wrapper {
    background-color: inherit;
}

.App .ant-anchor-link-title {
    color: rgba(255, 255, 255, 0.65) !important;
}

.App .ant-anchor-link-title:hover {
    color: #ffffff !important;
}

.App .ant-anchor-wrapper h4 {
    color: #ffffff;
    padding-left: .5em;
}

.position-dates {
    font-size: .8em;
    font-weight: bold;
    padding-top: 0;
    margin: 0;
}
@media (min-width: 768px) {
    .position-dates {
        margin: 1em 0;
    }
}