body {
    margin: 0px;
}

#Body {
    padding: 24px;
    background-color: #fff;
}

.ant-back-top {
    bottom: 25px;
    right: 25px;
}